/* global chrome */

import axios from "axios";
import { useEffect, useState } from "react";

import logo from "./logo.svg";
import "./App.css";
import { supabase } from "./supabaseClient";
import { ChakraProvider } from "@chakra-ui/react";
import Account from "./Account";
import Auth from "./Auth";

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    setSession(supabase.auth.session());
    console.log("@@@ session:", session);

    supabase.auth.onAuthStateChange((_event, session) => {
      console.log("@@@ supabase.auth.onAuthStateChange");
      setSession(session);
    });

    window.onstorage = (e) => {
      if (e.key === "supabase.auth.token") {
        const newSession = JSON.parse(e.newValue);
        console.log("@@@ window.onstorage");
        setSession(newSession?.currentSession);
      }
    };
  }, []);

  // useEffect(() => {
  //   chrome.tabs.query({ active: true, currentWindow: true }, (tabs) => {
  //     const url = new URL(tabs[0].url);
  //     const domain = url.hostname;
  //     console.log("Domain:", domain);
  //     console.log("url:", url);

  //     // this.getHeadlines(domain)
  //   });
  // }, []);

  return (
    <ChakraProvider>
      {!session ? (
        <Auth />
      ) : (
        <Account key={session.user.id} session={session} />
      )}
    </ChakraProvider>
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
